import axios from "axios";
import swal from "sweetalert";

export async function get(url, alertaEliminar) {
  try {
    const { data } = await axios.get(url);
    if (alertaEliminar) {
      swal({
        text: "Comando ejecutado",
        icon: "success",
      });
    }
    return data;
  } catch (e) {
    let salida = "Error desconocido";
    if (e.response) salida = e.response.data;
    swal({
      text: salida,
      icon: "error",
    });
    console.log(e)
  }
}

///////////////////////////////////////////////////////////////////////////////////////////
export async function post(url, body, showMensaje = true, isForm) {
  const headers = isForm && {
    "Content-Type": "application/json",
  };

  try {
    const { data } = await axios.post(url, body, headers);
   // console.log({data})
    if (showMensaje) {
      swal({
        text: data,
        icon: "success",
      });
    }
    return data;
  } catch (e) {
    console.log(e)
    let text = "Error desconocido";
    const icon = "error";
    if (e.response) text = e.response.data;
    swal({ text, icon });
  }
}


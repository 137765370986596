import { types } from "../types/types";

export const setTemaErr = (data) => ({
  type: types.storeTemaErr,
  payload: data,
});

export const setAlumnoErr = (data) => ({
  type: types.storeAlumnoErr,
  payload: data,
});

export const validaTemaCreate = ({ nombre, tiempoMaxMinutos }) => {
  let nombreErr;
  let tiempoMaxMinutosErr;
  let count = 0;

  if (!nombre) {
    nombreErr = "El nombre es necesario";
    count++;
  } else if (nombre === "") {
    nombreErr = "El nombre es necesario";
    count++;
  } else if (nombre.length < 3) {
    nombreErr = "El nombre es muy corto";
    count++;
  }

  if (!tiempoMaxMinutos) {
    tiempoMaxMinutosErr = "El tiempo max es necesario";
    count++;
  } else if (Number(tiempoMaxMinutos) < 5) {
    tiempoMaxMinutosErr = "El tiempo max debe ser mayor a 5 min";
    count++;
  }

  return {
    count,
    nombreErr,
    tiempoMaxMinutosErr,
  };
};

export const validaAlumno = ({
  nombre,
  email,
  password,
  password2,
  licenciaVence,
  tipo,
}) => {
  let nombreErr;
  let emailErr;
  let passwordErr;
  let password2Err;
  let licenciaVenceErr;

  let count = 0;

  if (!nombre) {
    nombreErr = "El nombre es necesario";
    count++;
  } else if (nombre === "") {
    nombreErr = "El nombre es necesario";
    count++;
  } else if (nombre.length < 3) {
    nombreErr = "El nombre es muy corto";
    count++;
  }

  if (!email) {
    emailErr = "El email es necesario";
    count++;
  } else if (email === "") {
    emailErr = "El email es necesario";
    count++;
  } else if (email.length < 3) {
    emailErr = "El email es muy corto";
    count++;
  }

  if (tipo === "add") {
    if (!password) {
      passwordErr = "El password es necesario";
      count++;
    } else if (password === "") {
      passwordErr = "El password es necesario";
      count++;
    } else if (password.length < 6) {
      passwordErr = "Para el password son 6 caracteres";
      count++;
    } else if (password !== password2) {
      passwordErr = "Los passwords no coinciden";
      count++;
    }
  } else {
    if (password) {
      if (password.length < 6) {
        passwordErr = "Para el password son 6 caracteres";
        count++;
      } else if (password !== password2) {
        passwordErr = "Los passwords no coinciden";
        count++;
      }
    }
  }

  if (!licenciaVence) {
    licenciaVenceErr = "La vigencia es necesaria";
    count++;
  }

  return {
    nombreErr,
    emailErr,
    passwordErr,
    password2Err,
    licenciaVenceErr,
    count,
  };
};

const isProd = true;
export const svr = isProd
  ? "https://back.impulsafinanzas.com"
  : "https://localhost:44359";

export const URL_GET_ALUMNOS = `${svr}/api/auth/alumnos`;
export const URL_ADD_ALUMNO = `${svr}/api/auth/addUser`;
export const URL_UPD_ALUMNO = `${svr}/api/auth/updUser`;
export const URL_DELE_ALUMNO = `${svr}/api/auth/deleUser`;

export const URL_GET_TEMAS = `${svr}/api/contenidos/temas`;
export const URL_ADD_TEMA = `${svr}/api/contenidos/addtema`;
export const URL_UPD_TEMA = `${svr}/api/contenidos/updtema`;
export const URL_DELE_TEMA = `${svr}/api/contenidos/deletema`;

export const URL_GET_LAMINAS = `${svr}/api/contenidos/laminas`;
export const URL_ADD_LAMINA = `${svr}/api/contenidos/addlamina`;
export const URL_UPD_LAMINA = `${svr}/api/contenidos/updlamina`;
export const URL_DELE_LAMINA = `${svr}/api/contenidos/delelamina`;

export const URL_GET_PREGUNTAS = `${svr}/api/contenidos/preguntas`;
export const URL_ADD_PREGUNTA = `${svr}/api/contenidos/addpregunta`;
export const URL_UPD_PREGUNTA = `${svr}/api/contenidos/updpregunta`;
export const URL_DELE_PREGUNTA = `${svr}/api/contenidos/delepregunta`;

export const URL_STATS_GENERALES = `${svr}/api/stats/generales`;
export const URL_STATS_USUARIO = `${svr}/api/stats/alumnostats`;
export const URL_STATS_EXMENES_FINALES = `${svr}/api/stats/ExamenesFinales`;

export const URL_GET_IMAGE = `${svr}/api/contenidos/getimage`;
export const URL_LOGIN = `${svr}/api/auth/login`;

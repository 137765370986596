import { consLogged } from "../../constants/consLogged";
import { URL_LOGIN } from "../../constants/urls";
import { post } from "../../utils/fetch";
import { types } from "../types/types";



export const startLogoff = () => {
    localStorage.removeItem("token")
    return (dispatch) => dispatch(setLogged(consLogged.NOTLOGGED))
}


//////////////////////////////////////////////////////////

export const verificaLogin = () => {
    return (dispatch) => {
        const token = localStorage.getItem("token")
        if(token){
            dispatch(setLogged(consLogged.LOGGED))
        } else {
            dispatch(setLogged(consLogged.NOTLOGGED))
        }

    }
}
////////////////////////////////////////////////////////
export const startLogin = () => {

    return async (dispatch, getState) => {
        const {login} = getState().formasReducer
        const data = await post(URL_LOGIN, login, false)
        if(data?.token)
        {
            localStorage.setItem('token', data.token)
            dispatch(setLogged(consLogged.LOGGED))
        }
        
    }
}

export const setLogged = (val) => ({
    type: types.setLogged,
    payload: val
})
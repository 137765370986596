import { types } from "../types/types";

const initial = {
  tema: {},
  alumno: {},
};

export const formasErrReducer = (state = initial, action) => {
  switch (action.type) {
    case types.storeTemaErr:
      return {
        ...state,
        tema: { ...action.payload },
      };

    case types.storeAlumnoErr:
      return {
        ...state,
        alumno: {...action.payload},
      };

    case types.cleanAlumnoErr:
      return {
        ...state,
        alumno: {},
      };

    case types.cleanTemaErr:
      return {
        ...state,
        tema: {},
      };

    default:
      return state;
  }
};

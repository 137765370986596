import { useState } from "react";
import { Layout, Menu } from "antd";
import {
  HomeOutlined,
  PoweroffOutlined,
  UnorderedListOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
import { useHistory } from "react-router-dom";
import { startLogoff } from "../context/actions/loginActions";
import { useDispatch } from "react-redux";

const { Content, Sider } = Layout;

////////////////////////////////////////////////////////////////////////////////
export function LayoutPage({ children }) {
  const dispatch = useDispatch();
  const history = useHistory();
  const [collapsed, setCollapsed] = useState(true);

  const onCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleClick = ({ key }) => {
    if (key !== "/logoff") history.push(key);
    else dispatch(startLogoff());
  };
  ////////////////////////////////////////////////////////////////////////////////
  return (
    <Layout style={{ minHeight: "100vh" }}>
      <Sider collapsible collapsed={collapsed} onCollapse={onCollapse}>
        <Logo isCollapsed={collapsed} />

        <Menu
          onClick={handleClick}
          style={{ marginTop: 50 }}
          theme="dark"
          defaultSelectedKeys={["1"]}
          mode="inline"
        >
          <Menu.Item key="/" icon={<HomeOutlined />}>
            Home
          </Menu.Item>
          <Menu.Item key="/temas" icon={<UnorderedListOutlined />}>
            Temas
          </Menu.Item>
          <Menu.Item key="/alumnos" icon={<UsergroupAddOutlined />}>
            Alumnos
          </Menu.Item>

          <Menu.Item key="/logoff" icon={<PoweroffOutlined />}>
            cerrar sesión
          </Menu.Item>
        </Menu>
      </Sider>

      <Layout style={{ backgroundColor: "#f1f1f1" }}>
        <Content style={{ paddingLeft: 10, paddingTop: 20, paddingRight: 10 }}>
          {children}
        </Content>
      </Layout>
    </Layout>
  );
}

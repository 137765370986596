import {
  URL_GET_ALUMNOS,
  URL_ADD_ALUMNO,
  URL_UPD_ALUMNO,
  URL_STATS_USUARIO,
  URL_DELE_ALUMNO,
  URL_STATS_EXMENES_FINALES,
} from "../../constants/urls";
import { get, post } from "../../utils/fetch";
import generatePassword from "../../utils/generatePassword";
import { validaAlumno } from "../../utils/validaciones";
import { types } from "../types/types";
import { setAlumnoErr } from "./erroresActions";
import { setAlumno } from "./formasActions";

//////////////////////////////////////////////////
//// DESCARGA DE alumnos
export const startLoadAlumnos = () => {
  return async (dispatch) => {
    try {
      const data = await get(URL_GET_ALUMNOS);
      dispatch(setDownloadedData(data));
    } catch (e) {
      console.log("Error en startLoadAlumnos api");
    }
  };
};

const setDownloadedData = (data) => ({
  type: types.storeAlumnos,
  payload: data,
});

//////////////////////////////////////////////////
//// NUEVO ALUMNO

export const startAddAlumno = () => {
  return async (dispatch, getState) => {
    let alumno = getState().formasReducer.alumno;

    const err = validaAlumno({ ...alumno, tipo: "add" });
    if (err.count !== 0) {
      dispatch(setAlumnoErr(err));
      return;
    }
    dispatch(setAlumnoErr(err));

    alumno.esAlumno = true;
    try {
      await post(URL_ADD_ALUMNO, alumno);
      dispatch(startLoadAlumnos());
      // dispatch(cleanAlumno());
    } catch (e) {
      console.log("Error en startAddAlumno api", e);
    }
  };
};

//////////////////////////////////////////////////
//// EDITA ALUMNO

export const startUpdAlumno = () => {
  return async (dispatch, getState) => {
    let alumno = getState().formasReducer.alumno;

    const err = validaAlumno(alumno);
    if (err.count !== 0) {
      dispatch(setAlumnoErr(err));
      return;
    }
    dispatch(setAlumnoErr(err));

    const url = `${URL_UPD_ALUMNO}/${alumno.id}`;
    try {
      await post(url, alumno);
      dispatch(startLoadAlumnos());
      // dispatch(cleanAlumno());
    } catch (e) {
      console.log("Error en startAddAlumno api", e);
    }
  };
};

//////////////////////////////////////////////////
//// DESCARGA DE STATS DE ALUMNO
export const startLoadAlumnoStats = (id) => {
  return async (dispatch) => {
    const url = `${URL_STATS_USUARIO}/${id}`;
    try {
      const data = await get(url);
      dispatch(setDownloadedAlumnoStatsData(data));
    } catch (e) {
      console.log("Error en startLoadAlumnos api", e);
    }
  };
};

const setDownloadedAlumnoStatsData = (data) => ({
  type: types.storeAlumnosStats,
  payload: data,
});


//////////////////////////////////////////////////////
//// CARGA DE EXAMENES FINALES DEL ALUMNO

export const startLoadExamenesFinales = (id) => {

  return async (dispatch, getSate) => {
    const url = `${URL_STATS_EXMENES_FINALES}/${id}`;
    console.log(url)
    try {
      const data = await get(url);
    

      dispatch(setExamenesFinales(data));
    } catch (e) {
      console.log("Error en startLoadAlumnos api", e);
    }
  }
}

const setExamenesFinales = data => ({
  type: types.setExamenesFinales,
  payload: data
})

/////////////////////////////////////////////////////
//// Genera password

export const createPassowrd = () => {
  const pass = generatePassword();
  return (dispatch) => {
    let dato = { password: pass };
    dispatch(setAlumno(dato));
    dato = { password2: pass };
    dispatch(setAlumno(dato));
  };
};

//////////////////////////////////////////////////
// STORE ALUMNO SELECTED

export const storeAlumnoSelected = (id) => {
  return (dispatch, getState) => {
    const { alumnos } = getState().listasReducer;
    const alumno = alumnos.filter((a) => a.id === id);

    dispatch(setAlumnoFull(alumno[0]));
  };
};

const setAlumnoFull = (alumno) => ({
  type: types.setAlumnoFull,
  payload: alumno,
});

////////////////////////////////////////////
//////Dele Alumno

export const startDeleAlumno = (id) => {
  return async (dispatch) => {
    const url = `${URL_DELE_ALUMNO}/${id}`;
    await post(url);
    dispatch(startLoadAlumnos());
  };
};

////////////////////////////////////////
///////// clear forma alumno

export const clearFormaAlumno = () => ({
  type: types.clearFormaAlumno,
  payload: null,
});

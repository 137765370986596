import { Input } from "antd";
import { colores } from "../../constants/colores";

export default function InputPat({ label,name, value, error, onChange }) {
  return (
    <div style={{ marginBottom: 10 }}>
      <label style={{ color: colores.label, fontWeight: 500 }}>{label}</label>
      <Input
        
        style={{ borderColor: error && error !== "" ? colores.error : "" }}
        name={name}
        value={value}
        onChange={onChange}
      />
      <div>
        {error && error !== "" ? (
          <div style={{ color: colores.error }}>{error}</div>
        ) : null}
      </div>
    </div>
  );
}

import { types } from "../types/types";

const initial = {
  alumnos: [],
  temas: [],
  laminas: [],
  preguntas: [],
  alumnoStats: [],
  examenesFinales: [],
};

export const listasReducer = (state = initial, action) => {
  switch (action.type) {

    ////////////////////////////////

    case types.storeAlumnos:
      return { ...state, alumnos: action.payload };

    case types.storeAlumnosStats:
      return { ...state, alumnoStats: action.payload };

    case types.storeTemas:
      return { ...state, temas: action.payload };

    case types.storePreguntas:
      return { ...state, preguntas: action.payload };

    case types.storeLaminas:
      return { ...state, laminas: action.payload };

      case types.setExamenesFinales:
      return { ...state, examenesFinales: action.payload };

    default:
      return state;
  }
};

export const types = {
  storeAlumnos: "[alumnos] store los alumnos",
  storeAlumnosStats: "[alumnos] store los alumnos stats",
  storeAlumno: "[alumno] store datos de forma alumno",
  setAlumnoFull: "[alumno] store datos de forma alumno full",
  clearFormaAlumno: "[Alumnos] clean forma alumno",

  storeAlumnoErr: "[AlumnoErr] store err de forma Alumno",
  cleanAlumnoErr: "[AlumnoErr] clean err de forma Alumno",

  storeTemas: "[temas] store los temas",
  storeTema: "[formas] store datos de forma tema",
  storeTemaFull: "[formas] store datos de forma tema full",
  cleanTema: "[formas] clean forma tema",

  storeTemaErr: "[formasErr] store err de forma tema create",
  cleanTemaErr: "[formasErr] clean err de forma tema create",

  storeStatsGenerales: "[stats] store los stats generales",
  storeLaminas: "[laminas] store las laminas de un tema",
  storeLamina: "[laminas] store datos de forma lamina",
  storeLaminaFull: "[laminas] store lamina selected",

  resetLaminaIndex: "[laminas] reset lamina index",
  setLaminaIndex: "[laminas] set lamina index",

  storePreguntas: "[preguntas] store las preguntas de un tema",
  clearPregunta: "[preguntas] clear Pregunta",
  
  mensajeTema: "[mensajes] tema mensaje",
  mensajeLamina: "[mensajes] lamina mensaje",
  mensajeAlumno: "[alumno] alumno mensaje",
  mensajeErrAlumno: "[alumnoErr] alumno err mensaje",

  storePregunta: "[preguntas] store pregunta",
  storeRespuesta: "[respuestas] store respuesta",
  storePreguntaFull: "[perguntas] store pregunta full",

  storeLogin: "[login] store login",
  setLogged: "[login] set logged",
  setExamenesFinales: "[examenes finales] stats examenes finales",
  
  
};

import { types } from "../types/types";

const initial = {
  lamina: 0,
};

export const claveValorReducer = (state = initial, action) => {
  switch (action.type) {

    case types.setLaminaIndex:
      return { ...state, lamina: action.payload };

      case types.resetLaminaIndex:
        return { ...state, lamina: 1 };


    default:
      return state;
  }
};

import { types } from "../types/types"


export const cleanTema = () => ({
    type: types.cleanTema,
    payload: null,
})

export const cleanTemaErr = () => ({
    type: types.cleanTemaErr,
    payload: null,
})

export const cleanAlumno = () => ({
    type: types.cleanAlumno,
    payload: null,
})

export const cleanAlumnoErr = () => ({
    type: types.cleanAlumnoErr,
    payload: null,
})

export const clearPregunta = () => ({
    type: types.clearPregunta,
    payload: null,
})
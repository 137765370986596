import { types } from "../types/types";

const initial = {
  statsGenerales: {
    alumnosActivos:0,
    accesosEsteMes:0,
    series:[]
  },
};

export const objetosReducer = (state = initial, action) => {

  switch (action.type) {

    case types.storeStatsGenerales:
      return { ...state, statsGenerales: action.payload }; 
  
    default:
      return state;
  }
};

import React, { useEffect, lazy, Suspense } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { LayoutPage } from "../layout/LayoutPage";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";

import Login from "../screens/auth/Login";
import { cargaInicial } from "../context/actions/iniciales";
import { Spin } from "antd";
import { consLogged } from "../constants/consLogged";
import { verificaLogin } from "../context/actions/loginActions";

const Alumnos = lazy(() => import("../screens/alumnos/listado/ListadoAlumnos"));
const Home = lazy(() => import("../screens/home/Home"));

const AlumnosDetalle = lazy(() =>
  import("../screens/alumnos/detalle/AlumnosDetalle")
);

const Temas = lazy(() => import("../screens/temas/listado/ListadoTemas"));
const TemasDetalle = lazy(() =>
  import("../screens/temas/detalle/TemasDetalle")
);

//////////////////////////////////////////////////////////////////
export default function Routes() {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.loggedReducer);

  useEffect(async () => {
    await dispatch(verificaLogin());
    dispatch(cargaInicial());
  }, [dispatch]);

  if (logged === consLogged.INICIANDO) return <div></div>;

  //////////////////////////////////////////////////////////////////
  return (
    <Router>
      <Switch>
        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/login"
          component={Login}
        />
        <LayoutPage>
          <Suspense
            fallback={
              <div style={{ marginTop: 30 }}>
                {" "}
                <Spin size="large" />
              </div>
            }
          >
            {/* <PrivateRoute isAuthenticated={logged}  exact path="/" component={Home} /> */}
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/alumnos"
              component={Alumnos}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/"
              component={Home}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/alumnoDetalle/:id"
              component={AlumnosDetalle}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/temas"
              component={Temas}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/temaDetalle/:id"
              component={TemasDetalle}
            />
          </Suspense>
        </LayoutPage>
      </Switch>
    </Router>
  );
}

import { types } from "../types/types";
import { tema, lamina, alumno, pregunta, login } from "./consReducers";

const initialForma = {
  tema,
  lamina,
  alumno,
  pregunta,
  login
};

export const formasReducer = (state = initialForma, action) => {
  switch (action.type) {
    /////////////////////////////////
    case types.storeTema:
      return {
        ...state,
        tema: join(state.tema, action.payload),
      };

    case types.storeTemaFull:
      return {
        ...state,
        tema: action.payload,
      };


      ////////////////////////////////////////////
      case types.storeLogin:
        return {
          ...state,
          login: join(state.login, action.payload),
        };
  
    ////////////////////////////////////////////////
    case types.storeLamina:
      return {
        ...state,
        lamina: join(state.lamina, action.payload),
      };

    case types.storeLaminaFull:
      return {
        ...state,
        lamina: action.payload,
      };

    ////////////////////////////////////////////////
    case types.storeAlumno:
      return {
        ...state,
        alumno: join(state.alumno, action.payload),
      };

    case types.setAlumnoFull:
      return {
        ...state,
        alumno: action.payload,
      };

    ////////////////////////////////////////////////

    case types.storePreguntaFull:
      return {
        ...state,
        pregunta: action.payload,
      };

    case types.storePregunta:
      return {
        ...state,
        pregunta: { ...state.pregunta, nombre: action.payload },
      };

      case types.clearPregunta:
        return {
          ...state,
          pregunta,
        };
    /////////////////////////////////////////
    case types.storeRespuesta:
      const { name, value } = action.payload.elemento;
      const { index } = action.payload;
      const nuevasRespuestas = state.pregunta.respuestas.map((resp, i) => {
        if (i === index) {
          return { ...resp, [name]: value };
        } else {
          return resp;
        }
      });

      return {
        ...state,
        pregunta: { ...state.pregunta, respuestas: nuevasRespuestas },
      };

    ////////////////////////
    case types.clearFormaAlumno:
      return {
        ...state,
        alumno,
      };

    case types.cleanTema:
      return {
        ...state,
        tema,
      };


    default:
      return state;
  }
};

const join = (state, payload) => ({ ...state, ...payload });

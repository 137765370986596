import {types} from '../types/types'

const initial = {}


export const loadingReducer = (state = initial, action) => {

    switch (action.type) {


        default:
            return state;
    }
}
import {
  URL_GET_TEMAS,
  URL_ADD_TEMA,
  URL_UPD_TEMA,
  URL_DELE_TEMA,
} from "../../constants/urls";
import { get, post } from "../../utils/fetch";
import { validaTemaCreate } from "../../utils/validaciones";
import { types } from "../types/types";
import { cleanTema, cleanTemaErr } from "./cleanActions";
import { setTemaErr } from "./erroresActions";
import { mensajeTema } from "./mensajesAction";

//////////////////////////////////////////////////
//// DESCARGA DE alumnos
export const startLoadTemas = () => {
  return async (dispatch) => {
    try {
      const data = await get(URL_GET_TEMAS);
      dispatch(setDownloadedData(data));
    } catch (e) {
      console.log("Error en startLoadTemas api", e);
    }
  };
};

const setDownloadedData = (data) => ({
  type: types.storeTemas,
  payload: data,
});

//////////////////////////////////////////////////
//// NUEVO TEMA

export const startAddTema = () => {
  return async (dispatch, getState) => {
    dispatch(cleanTemaErr());
    const body = getState().formasReducer.tema;
    const err = validaTemaCreate(body);

    if (err.count !== 0) {
      dispatch(setTemaErr(err));
      return;
    }

    try {
      const data = await post(URL_ADD_TEMA, body);
      dispatch(startLoadTemas());
      dispatch(cleanTemaErr());
      dispatch(cleanTema());
      dispatch(mensajeTema("Tema agregado correctamente"));
      setTimeout(() => {
        dispatch(mensajeTema(""));
      }, 3000);
    } catch (e) {
      console.log("Error en startAddTema api", e);
    }
  };
};

//////////////////////////////////////////////////
//// EDITA TEMA

export const startUpdTema = () => {
  return async (dispatch, getState) => {
    dispatch(cleanTemaErr());
    const body = getState().formasReducer.tema;
    const newBody = {
      id: body.id,
      nombre: body.nombre,
      tiempoMaxMinutos: body.tiempoMaxMinutos,
    };
    const err = validaTemaCreate(body);

    if (err.count !== 0) {
      dispatch(setTemaErr(err));
      return;
    }

    try {
      const data = await post(URL_UPD_TEMA, newBody);
      dispatch(startLoadTemas());
      dispatch(cleanTemaErr());
    } catch (e) {
      console.log("Error en startUpdTema api", e);
    }
  };
};

//////////////////////////////////////////////////
//// SET TEMA SELECTED

export const startSetTemaSelected = (id) => {
  return (dispatch, getState) => {
    const { temas } = getState().listasReducer;
    const tema = temas.filter((t) => t.id === Number(id));
    dispatch(storeTemaSelected(tema[0]));
  };
};

//
const storeTemaSelected = (id) => ({
  type: types.storeTemaFull,
  payload: id,
});

//////////////////////////////////////////////////
//// DELE TEMA

export const startDeleTema = (id) => {
  return async (dispatch) => {
    const url = `${URL_DELE_TEMA}/${id}`;
    await post(url);
    dispatch(startLoadTemas());
  };
};

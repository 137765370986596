import { types } from "../types/types";

const initial = {
  mensajeTema:"",
  mensajeLamina:"",
  mensajeAlumno:"",
  mensajeErrAlumno:"",
};

export const mensajesReducer = (state = initial, action) => {
  switch (action.type) {
   
      case types.mensajeTema:
      return {
        ...state,
        mensajeTema: action.payload,
      };

      case types.mensajeLamina:
      return {
        ...state,
        mensajeLamina: action.payload,
      };

      case types.mensajeAlumno:
      return {
        ...state,
        mensajeAlumno: action.payload,
      };

      case types.mensajeErrAlumno:
        return {
          ...state,
          mensajeErrAlumno: action.payload,
        };

    default:
      return state;
  }
};

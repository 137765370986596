import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

import { claveValorReducer } from "../reducers/claveValorReducer";
import { listasReducer } from "../reducers/listasReducer";
import { loadingReducer } from "../reducers/loadingReducer";
import { objetosReducer } from "../reducers/objetosReducer";
import { formasReducer } from "../reducers/formasReducer";
import { formasErrReducer } from "../reducers/formasErrReducer";
import { mensajesReducer } from "../reducers/mensajesReducer";
import { loggedReducer } from "../reducers/loggedReducer";

const reducers = combineReducers({
  claveValorReducer: claveValorReducer,
  listasReducer: listasReducer,
  loadingReducer: loadingReducer,
  objetosReducer: objetosReducer,
  formasReducer: formasReducer,
  formasErrReducer: formasErrReducer,
  mensajesReducer: mensajesReducer,
  loggedReducer: loggedReducer
});

const composeEnhancers =
  (typeof window !== "undefined" &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export const store = createStore(
  reducers,
  composeEnhancers(applyMiddleware(thunk))
);

import { startLoadAlumnos } from "./alumnosActions";
import { startLoadStatsGenerales } from "./statsActions";
import { startLoadTemas } from "./temasActions";

  export const cargaInicial = () => {

    return async (dispatch) => { 
     await  dispatch(startLoadAlumnos());
     await  dispatch(startLoadTemas());
     await  dispatch(startLoadStatsGenerales());
    }
}
export default function Logo({isCollapsed}) {

    const texto = isCollapsed ? <div>IM</div> : <div>Impulso <br />Finanzas</div>

    return (
        <div style={{fontSize: 20, width: '100%', textAlign: 'center', marginTop: 10, color: 'orange'}}>
            {texto}
        </div>
    )
    
}

import { types } from "../types/types";

export const mensajeTema = (mensaje) => ({
  type: types.mensajeTema,
  payload: mensaje,
});

export const mensajeLamina = (mensaje) => ({
  type: types.mensajeLamina,
  payload: mensaje,
});

export const mensajeAlumno = (mensaje) => ({
  type: types.mensajeAlumno,
  payload: mensaje,
});


export const mensajeErrAlumno = (mensaje) => ({
  type: types.mensajeErrAlumno,
  payload: mensaje,
});

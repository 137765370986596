import { URL_STATS_GENERALES, URL_STATS_USUARIO } from "../../constants/urls";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";

//////////////////////////////////////////////////
//// DESCARGA DE STATS GENERALES
export const startLoadStatsGenerales = () => {
  return async (dispatch) => {
    try {
      const data = await get(URL_STATS_GENERALES);
      dispatch(setDownloadedData(data));
    } catch (e) {
      console.log("Error en startLoadStatsGenerales api", eval);
    }
  };
};

const setDownloadedData = (data) => ({
  type: types.storeStatsGenerales,
  payload: data,
});

//////////////////////////////////////////////////
//// DESCARGA DE STATS USUARIO
export const startLoadStatsUsuario = (alumnoID) => {
  return async (dispatch) => {
    try {
      const data = await get(`${URL_STATS_USUARIO}/${alumnoID}`);
      dispatch(setDownloadedDataUsuario(data));
    } catch (e) {
      console.log("Error en startLoadStatsUsuario api", eval);
    }
  };
};

const setDownloadedDataUsuario = (data) => ({
  type: types.storeStatsGenerales,
  payload: data,
});